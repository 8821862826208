import {
    MeshBasicMaterial, Shape, Mesh, ExtrudeBufferGeometry, Object3D, Material
} from 'three';

import { ThreeUtils } from '@wemap/ar';

let itineraryGuide: Object3D | null = null;
let guideMaterial: Material | null = null;
let guideMaterialBorder: Material | null = null;

const DEFAULT_MATERIAL_ITI = new MeshBasicMaterial({
    color: 0x0f7fed,
    opacity: 0.7,
    transparent: true
});

const DEFAULT_MATERIAL_BORDER = new MeshBasicMaterial({
    color: 0xffffff,
    opacity: 0.7,
    transparent: true
});

class LivemapItineraryGuide {

    static async draw(material = DEFAULT_MATERIAL_ITI, materialBorder = DEFAULT_MATERIAL_BORDER) {

        if (itineraryGuide && material === guideMaterial && materialBorder === guideMaterialBorder) {
            return ThreeUtils.cloneMaterialRecursively(itineraryGuide.clone());
        }

        const lineAngle = (45 / 180) * Math.PI;
        const lineLength = 0.75;
        const lineWidth = 0.15;
        const extrudeSettings = {
            steps: 1,
            depth: 0.005,
            bevelEnabled: true,
            bevelThickness: 0.01,
            bevelSize: 0.01,
            bevelSegments: 2
        };

        const outsideTopCorner = {
            x: 0,
            y: (Math.sin(lineAngle) * lineLength) / 2
        };

        const outsideLeftCorner = {
            x: outsideTopCorner.x - Math.cos(lineAngle) * lineLength,
            y: outsideTopCorner.y - Math.sin(lineAngle) * lineLength
        };

        const outsideRightCorner = {
            x: outsideTopCorner.x + Math.cos(lineAngle) * lineLength,
            y: outsideTopCorner.y - Math.sin(lineAngle) * lineLength
        };

        const insideRightCorner = {
            x: outsideRightCorner.x - Math.cos(lineAngle) * lineWidth,
            y: outsideRightCorner.y - Math.sin(lineAngle) * lineWidth
        };

        const insideTopCorner = {
            x: outsideTopCorner.x,
            y: outsideTopCorner.y - Math.sqrt(2 * lineWidth ** 2)
        };

        const insideLeftCorner = {
            x: outsideLeftCorner.x + Math.cos(lineAngle) * lineWidth,
            y: outsideLeftCorner.y - Math.sin(lineAngle) * lineWidth
        };

        const shape = new Shape();
        shape.moveTo(outsideLeftCorner.x, outsideLeftCorner.y);
        shape.lineTo(outsideTopCorner.x, outsideTopCorner.y);
        shape.lineTo(outsideRightCorner.x, outsideRightCorner.y);
        shape.lineTo(insideRightCorner.x, insideRightCorner.y);
        shape.lineTo(insideTopCorner.x, insideTopCorner.y);
        shape.lineTo(insideLeftCorner.x, insideLeftCorner.y);
        shape.lineTo(outsideLeftCorner.x, outsideLeftCorner.y);

        const geometry = new ExtrudeBufferGeometry(shape, extrudeSettings);
        const mesh = new Mesh(geometry, material);

        const border = lineLength * 0.02;
        const shapeOutline = new Shape();
        shapeOutline.moveTo(outsideLeftCorner.x - border, outsideLeftCorner.y);
        shapeOutline.lineTo(outsideTopCorner.x, outsideTopCorner.y + border);
        shapeOutline.lineTo(outsideRightCorner.x + border, outsideRightCorner.y);
        shapeOutline.lineTo(insideRightCorner.x, insideRightCorner.y - border);
        shapeOutline.lineTo(insideTopCorner.x, insideTopCorner.y - border);
        shapeOutline.lineTo(insideLeftCorner.x, insideLeftCorner.y - border);
        shapeOutline.lineTo(outsideLeftCorner.x - border, outsideLeftCorner.y);
        shapeOutline.holes.push(shape);
        const geometryOutline = new ExtrudeBufferGeometry(shapeOutline, extrudeSettings);
        const meshOutline = new Mesh(geometryOutline, materialBorder);

        mesh.rotation.x = Math.PI / -2;
        meshOutline.rotation.x = Math.PI / -2;
        mesh.rotateZ(Math.PI);
        meshOutline.rotateZ(Math.PI);

        const container = new Object3D();
        container.add(mesh);
        container.add(meshOutline);

        itineraryGuide = container;
        guideMaterial = material;
        guideMaterialBorder = materialBorder;

        return container;
    }
}

export default LivemapItineraryGuide;
